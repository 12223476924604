import { useEffect, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import Button from 'components/lib/Button';
import Text from 'components/lib/Text';
import { useGivingFormData, useQueryParam } from 'hooks';
import { Designation, DesignationInstance, SelectedDesignation } from 'types';
import { IDonateQueryParam } from 'types/QueryParams';
import { usdFormatter } from 'utils/formatters';
import { parseAvailableDesignationQueryParams } from 'utils/queryParamUtils';
import './DesignationPicker.scss';
import { DesignationError, DesignationRow } from './DesignationRow';

interface DesignationPickerProps {
  designationsFromConfig: string[];
  defaultDesignationId?: string;
  promptText?: string;
  singleDesignation?: boolean;
  remountGiftOptions?: () => void;
}

export const DesignationPicker = ({
  designationsFromConfig,
  defaultDesignationId,
  promptText,
  singleDesignation,
  remountGiftOptions
}: DesignationPickerProps) => {
  const { control, getValues, watch, setValue } = useFormContext();
  const { campaignDesignations } = useGivingFormData();
  const designationQueryParam = useQueryParam(IDonateQueryParam.Designation);
  const designationsQueryParam = useQueryParam(IDonateQueryParam.Designations);
  const parsedDesignationsFromQuery = parseAvailableDesignationQueryParams(
    designationsQueryParam,
    campaignDesignations
  );
  const [giftOptionsAreHidden, setGiftOptionsAreHidden] = useState<boolean>(
    !!parsedDesignationsFromQuery.length
  );
  const giftAmount = watch('giftAmount');

  const prePopulatedDesignations: DesignationInstance[] = useMemo(() => {
    const result: DesignationInstance[] = [];
    if (parsedDesignationsFromQuery) {
      result.push(...parsedDesignationsFromQuery);
    }

    if (!result.length && designationQueryParam) {
      // we received a single query param
      const queryDesignation = campaignDesignations.find(
        ({ id }) => id === designationQueryParam
      );

      if (queryDesignation) {
        result.push({
          key: uuid(),
          amount: giftAmount,
          ...queryDesignation
        });
      }
    }

    if (
      !result.length &&
      (defaultDesignationId || designationsFromConfig.length === 1)
    ) {
      const desToPopulate = () => {
        if (defaultDesignationId) {
          return campaignDesignations.find(
            ({ id }) => id === defaultDesignationId
          );
        }
        return campaignDesignations.find(
          ({ id }) => id === designationsFromConfig[0]
        );
      };
      result.push({
        key: uuid(),
        amount: giftAmount,
        ...desToPopulate()
      });
    }

    return result;
  }, [
    defaultDesignationId,
    designationQueryParam,
    designationsFromConfig,
    campaignDesignations,
    giftAmount,
    parsedDesignationsFromQuery
  ]);

  // per convo with product, only designations on the config
  // will be populated in the dropdown.
  // If a valid designation is provided in query params
  // that is not on the config, it will populate on
  // the form but will not be an option in the dropdown
  // if the donor clears the selection for any reason
  const designationObjectsForDropdown = useMemo(
    () =>
      designationsFromConfig.reduce((results, id) => {
        const foundDes = campaignDesignations.find((cd) => cd.id === id);
        if (foundDes) {
          results.push(foundDes);
        }
        return results;
      }, [] as Designation[]),
    [campaignDesignations, designationsFromConfig]
  );

  const {
    field: { value, onChange },
    fieldState: { error: fieldError }
  } = useController({
    name: 'designation',
    control,
    /**
     * If there is only one available designation, we save it into form state as the selected designation
     *
     * If there are multiple designations, we defer to any query params specifying designation, then fallback to the configured default designation,
     * and fall back once more to an empty designation field
     */
    defaultValue: prePopulatedDesignations.length
      ? prePopulatedDesignations
      : [
          {
            key: uuid()
          }
        ]
  });

  useEffect(() => {
    // if designations are populated from query params, gift options never mount
    // and initial gift option is never set, preventing form from being submitted
    // setting initial gift amount in that event
    if (parsedDesignationsFromQuery.length > 0) {
      const donationAmountFromParams = parsedDesignationsFromQuery.reduce(
        (acc: number, curr: { amount?: number }) => acc + (curr.amount ?? 0),
        0
      );
      setValue('giftAmount', donationAmountFromParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     * When giftAmount changes, it means the gift options were visible, which in turn means that
     * we must only have one designation. In this case, we just update the amount on the single
     * designation we have to keep it in sync. This helps us show the text next to the single
     * designation and have it match whatever the selected amount is.
     */
    const currentDesignations = getValues('designation');
    if (giftAmount && currentDesignations.length <= 1) {
      setValue('designation', [
        {
          ...(currentDesignations?.[0] ?? {}),
          amount: giftAmount ?? 0
        }
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftAmount]);

  /**
   * Listening for the scenario when the amount of designations drops back down to one. This hides the amount box for the
   * single designation and toggles the GiftOptions to "remount"
   */
  useEffect(() => {
    if (value.length === 1) {
      // we only want to remount if we don't have available `designations` query params
      if (parsedDesignationsFromQuery.length === 0) {
        setGiftOptionsAreHidden(false);
        remountGiftOptions?.();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  /**
   * If there is only one designation option, we don't show the designation picker.
   */
  if (designationsFromConfig.length === 1) return null;

  /**
   * Here we have a list of designation ids that have already been selected. These will be filtered out of the combo box
   * in the designation picker.
   */
  const selectedDesignations =
    getValues('designation')?.map(({ id }: SelectedDesignation) => id) ?? [];

  const donationAmount = value.reduce(
    (acc: number, curr: { amount?: number }) => acc + (curr.amount ?? 0),
    0
  );

  /**
   * Errors come back in one of two ways.
   *
   * If the donation total is not in range of their selected minimum and $250,000, the error looks like { type: 'is-in-range', message: string }
   *
   * If the donation total is fine, but maybe they forgot to select a designation or enter an amount for one, then we get back an array []
   * the index of which represent which designation is invalid and whether the title or amount is invalid.
   */

  let errorMessage;

  if (fieldError?.type === 'is-in-range') {
    const { message } = fieldError;
    errorMessage = message;
  }

  const error = fieldError as unknown as DesignationError[];

  return (
    <div className="GF-DesignationPicker">
      <div className="GF-DesignationPicker__Container">
        <div className="GF-DesignationPicker__Title">
          <Text variant="h5">{promptText}</Text>
          {giftOptionsAreHidden ||
            (value.length !== 1 && (
              <Text
                variant="h5"
                className="GF-DesignationPicker__Amount--Label"
              >
                Amount
              </Text>
            ))}
        </div>
        {value
          .slice(0, singleDesignation ? 1 : undefined)
          .map(
            (
              designation: SelectedDesignation,
              i: number,
              arr: SelectedDesignation[]
            ) => (
              <DesignationRow
                key={designation.key}
                designation={
                  designation?.title || designation?.amount ? designation : {}
                }
                onChange={(newData) => {
                  const newValue = [...value];
                  newValue[i] = {
                    ...designation,
                    ...newData
                  };

                  onChange(newValue);
                }}
                onDelete={() => {
                  const newValue = [...value];
                  newValue.splice(i, 1);
                  onChange(newValue);
                }}
                onClear={() => {
                  const { key, amount } = designation;
                  const newValue = [...value];
                  newValue[i] = { key, amount };
                  onChange(newValue);
                }}
                designations={designationObjectsForDropdown.filter(
                  (des) => !selectedDesignations.includes(des.id)
                )}
                allowAmountEdit={giftOptionsAreHidden || arr.length !== 1}
                showDelete={arr.length !== 1}
                error={error?.[i]}
              />
            )
          )}
        {errorMessage && (
          <Text variant="h6" className="GF-DesignationPicker__Error">
            {errorMessage}
          </Text>
        )}
        {!singleDesignation &&
          selectedDesignations.length < designationsFromConfig.length && (
            <Button
              className="GF-DesignationPicker__AddDesignation"
              name="addDesignation"
              onClick={() => {
                /**
                 * When we click add designation, we add an empty designation to form state, and start it off with just a key
                 * property. This helps us handle the react key necessary for mapping.
                 */
                onChange([...value, { key: uuid() }]);
                setGiftOptionsAreHidden(true);
              }}
            >
              + Add New Designation
            </Button>
          )}

        {(value.length > 1 || parsedDesignationsFromQuery.length > 0) && (
          <div className="GF-DesignationPicker__DonationAmount">
            <div className="GF-DesignationPicker__DonationAmount--Background" />
            <div className="GF-DesignationPicker__DonationAmount--Text">
              <Text variant="h8">Donation Amount</Text>
              <Text variant="h8">{usdFormatter.format(donationAmount)}</Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
